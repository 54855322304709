<template>
  <div class="Memberlist">
    <div class="top-title">
      <div class="top-text">会员列表</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
          <el-form-item label="会员等级" >
            <el-select v-model="seeks.a" placeholder="选择会员等级"><el-option v-for="(r, i) in seeklist.one" :key="i" :label="r.label" :value="r.value"></el-option></el-select>
          </el-form-item>

         <el-form-item label="手机号" >
                  <el-input v-model="seeks.b"></el-input>
          </el-form-item>

          <el-form-item label="停用" >
            <el-select v-model="seeks.c" placeholder="停用"><el-option v-for="(r, i) in seeklist.two" :key="i" :label="r.label" :value="r.value"></el-option></el-select>
          </el-form-item>

          <el-form-item label="违规类型" >
            <el-select v-model="seeks.d" placeholder="选择违规类型"><el-option  v-for="(r, i) in seeklist.three" :key="i" :label="r.label" :value="r.value"></el-option></el-select>
          </el-form-item>

          <el-form-item label="待审核经理" >
             <el-select v-model="seeks.e" placeholder="请选择"><el-option  v-for="(r, i) in seeklist.five" :key="i" :label="r.label" :value="r.value"></el-option></el-select>
          </el-form-item>

              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
      
    </div>


    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" border :row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="true_name" label="会员姓名"> </el-table-column>

        <el-table-column prop="nickname" label="会员昵称"> </el-table-column>
        <el-table-column prop="inviter_name" label="上级推荐人"> </el-table-column>
        <el-table-column prop="inviter_mobile" label="推荐人手机号"> </el-table-column>

        <el-table-column prop="member_levelname" label="会员级别"> </el-table-column>
        <el-table-column prop="bu_mobile" label="手机号"> </el-table-column>
        <el-table-column prop="regtime" label="注册时间"> </el-table-column>
        <el-table-column prop="break_total" label="违规次数"> </el-table-column>

        <el-table-column label="操作" width="100" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"
              type="text"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "../../api/apiurl/apiurl.js"
export default {
  data() {
    return {
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      //加载
      loading: this.$common.loading,

      // 下拉框
      seeks: {},
      seeklist:fakelist.memberlist,



      list:[],
    };
  },
  created() {
    this.getSong()
  },
  mounted() {},

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'WorkerEntity',
        handleMode:'fetchall',
        query:{
         memberLevel:this.seeks.a,
         mobile:this.seeks.b,
         stoped:this.seeks.c,
         breakType:this.seeks.d,
         managerAppStatus:this.seeks.e,
        }, })
        console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },


    // 表格根据停用次数改变背景
    tableRowStyle({ row, rowIndex }) {
      if (row.break_total == 1) { return "block-up-one"; } else if (row.break_total >= 2) { return "block-up-two"}
    },

    // 跳转详情页
    handleClick(row) {
      this.$router.push({ path: `/home/memberlistpar/${row.id}` });
    },

    // 搜索栏
    seek(){
      this.currentPage = 1;
        this.getSong()
    },


    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
@import "../../assets/css/liststyle.css";
</style>